@import "../../../../../style/index";

.statistics {
  &__loader {
    position: relative; }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px; }

  &__period,&__custom-period {
    display: flex;
    gap: 20px;
    align-items: flex-end; }

  &__custom-period {
    border: 2px solid $color-silver-chalice;
    border-radius: 8px;
    padding: 1rem;

    legend {
      color: $color-silver-chalice;
      letter-spacing: -0.44px;
      line-height: 22px; }

    .add-event__block {
      margin: 0; }

    .btn-primary {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;

      svg {
        display: initial; }

      &:disabled {
        svg {
          display: initial;
          g {
            stroke: $color-silver-chalice; } } } } }

  &__prev-button {
    svg {
      transform: rotate(90deg); } }
  &__next-button {
    svg {
      transform: rotate(-90deg); } }


  &__select, .custom-select {
    width: 120px; }

  &__select {
    margin-bottom: 16px; }

  &__date-picker, &__date-picker input {
    width: 120px;

    &:disabled {
      color: $color-silver-chalice;

      &:hover {
        border-color: $color-seashell; } } }

  &__info-wrapper {
    display: flex;
    gap: 60px;
    margin-top: -70px;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none; } }

  &__info {
    width: 220px; }

  &__title {
    margin-bottom: 10px;
    font-size: 18px;
    text-align: center; }

  &__info-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;

    &--total {
      margin-top: 8px; } }

  &__chart {
    height: 400px;
    position: relative;
    font-size: 14px;
    margin-bottom: 40px; }

  .ReactTable {
    margin-bottom: 30px;

    .rt-tbody .rt-tr-group .rt-td,
    .rt-thead.-header .rt-tr .rt-th {
      padding: 20px 5px;
      text-wrap: wrap; } }

  .custom-tooltip {
    position: static;
    display: grid;
    grid-template-columns: 150px auto auto;
    gap: 10px 20px;
    max-height: 280px;
    overflow: auto; } }

.tickets-info-modal {
  position: static;
  display: grid;
  gap: 10px 30px;
  height: max-content;
  text-align: left;
  grid-template-columns: repeat(4, auto) 100px 150px;
  margin-top: 30px;

  &--col-5 {
    grid-template-columns: repeat(3, auto) 100px 150px; } }

.recharts-bar-rectangle {
  cursor: pointer; }
