@import "../../style/index";

.partizan-info-modal {
    a, h3, p, button, li {
        font-family: var(--app-font-family) !important; }

    ul {
        list-style: none; } }

.partizan-open-selling {
    font-family: var(--app-font-family);
    color: $color-text-dark;
    margin-bottom: 30px;

    .input-valid-error {
        color: var(--reject-button-color);
        font-size: 14px;
        margin-top: 5px; }

    .block-input-validation {
        display: flex;
        flex-direction: column;

        .input-field-validation {
            display: flex;
            flex: 1;
            min-height: 23px; } }

    .loader {
        height: 100%;
        width: 100%; }
    .btn-primary {
        background-color: var(--primary-color);
        font-family: var(--app-font-family);
        font-weight: 500;
        line-height: 22px;
        &:hover {
            -webkit-box-shadow: 0px 0px 8px 0px;
            -moz-box-shadow: 0px 0px 8px 0px var(--primary-color);
            box-shadow: 0px 0px 8px 0px var(--primary-color); } }

    .stepper {
        &__header {
            &-hero {
                path {
                    fill: var(--primary-color); } }
            &-indicator {
                &_active {
                    background: var(--primary-color); } } } }
    .btn-secondary {
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        font-family: var(--app-font-family);
        font-weight: 500;

        &:hover {
            -webkit-box-shadow: 0px 0px 8px 0px;
            -moz-box-shadow: 0px 0px 8px 0px var(--primary-color);
            box-shadow: 0px 0px 8px 0px var(--primary-color); } }
    &__picker-wrapper {
        display: flex;
        justify-content: space-between;
        > * {
            flex: 1;
            @include respond-to('small-max') {
                width: auto;
                flex: 1; } }
        > *:not(:last-child) {
            margin-right: 12px; }
        > :nth-child(2) {
          flex: 1.2; } }
    .react-datepicker {
        font-family: var(--app-font-family);
        color: #0C0019; }
    .seasonpass__field-text-label {
        font-weight: 500;
        color: #0C0019; }
    .seasonpass__select__placeholder {
        color: #9FA0A6; }
    input {
        font-family: var(--app-font-family);
        color: #0C0019; }
    input::placeholder {
        color: #9FA0A6;
        font-family: var(--app-font-family); }
    .checkbox {
        label {
            width: auto;
            &::before, &::after {
                border-color: var(--primary-color) !important; } } }
    .react-datepicker {
        &__day--keyboard-selected {
            background-color: var(--primary-color); }
        &__day--keyboard-selected:hover {
            background-color: var(--primary-color); }
        &__day--selected {
            background-color: var(--primary-color); }
        &__day--selected:hover {
            background-color: var(--primary-color); }
        &__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: var(--primary-color); }
        &__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
            background-color: var(--primary-color); } }
    &__select {
        &__single-value {
            &--is-disabled.partizan-open-selling__select__single-value--is-disabled {
                color: $color-silver-chalice; } }
        &__value-container {
          padding: 0 8px !important;
          & > div {
            margin: 0;
            padding: 0; } }
        &__control.partizan-open-selling__select__control {
            border-radius: 2px;
            border-color: $color-seashell;
            padding: 0;
            &:hover {
                border-color: $color-seashell; }
            &--is-focused.partizan-open-selling__select__control--is-focused {
                box-shadow: none; } }
        &__indicator-separator.partizan-open-selling__select__indicator-separator {
            visibility: hidden; }
        &__option.partizan-open-selling__select__option {
            &--is-focused.partizan-open-selling__select__option--is-focused {
                background: $color-seashell; }
            &--is-selected.partizan-open-selling__select__option--is-selected {
                background-color: $color-partizan-primary; } } }
    &__input_block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem; }
    &__select-tickets {
        @include respond-to('small-max') {
            flex-direction: column;
            .partizan-open-selling__total {
                margin-top: 0; }
            .partizan-open-selling__step-info {
                width: calc( 100% - 1rem );
                padding: 0; } } }
    &__wrapper {
        border-radius: 12px;
        border: 1px solid $color-seashell; }
    &__logo {
        padding: 24px 0;

        img {
          width: 46px;
          height: 46px; }

        @include respond-to('small-max') {
            padding: 12px 0; }
        h2 {
            color: #0C0019;
            text-transform: uppercase;
            font-weight: 500;
            margin: 0 0 0 14px;
            font-size: 28px;
            @include respond-to('small-max') {
                font-size: 21px; } } }
    &__stepper {
        &-header {
            color: $color-light;
            padding: 48px 48px 200px 48px;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: $color-gray;

            @include respond-to('small-max') {
                background-position: top left;
                padding: 48px 12px 200px 12px; }
            h2:first-child {
                margin: 20px 0 0 0; }
            &-title {
                margin: 0;
                font-size: 36px;
                font-weight: 700;
                @include respond-to('small-max') {
                    font-size: 26px; } }
            &-event-name {
                margin: 0;
                font-size: 22px; }

            &-subtitle {
                margin: 0;
                font-size: 14px;
                font-weight: 300; }
            &-info {
                margin-top: 40px;
                &-time {
                    font-size: 18px;
                    margin: 5px 0 0 0;
                    font-weight: 500;
                    margin-right: 4px;
                    @include respond-to('small-max') {
                        font-size: 14px; } }
                &-location {
                    display: flex;
                    align-items: center;
                    p {
                        margin: 0 0 0 5px;
                        @include respond-to('small-max') {
                            font-size: 12px; } } }
                // div:last-child
                //     color: #0C0019
                //     padding: 12px 16px
                //     border-radius: 5px
 } }                //     background: $color-light
        &-action {
            border-top: 1px solid $color-seashell;
            padding: 24px 48px 32px 48px;
            @include respond-to('small-max') {
                padding: 12px; }
            button {
                width: 150px; }
            &-btns {
                display: flex;
                justify-content: space-between; } } }
    &__step {
        display: flex;
        color: $color-text-dark;
        @include respond-to('small-max') {
            flex-direction: column; }
        &-hero {
            padding: 32px 48px;
            flex: 1;
            @include respond-to('small-max') {
                padding: 12px; }
            iframe {
                width: 100% !important; }

            .checkbox {
              width: max-content; } }
        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 32px 48px;
            background: $color-seashell;
            min-width: 250px;
            @include respond-to('small-max') {
                padding: 12px; } } }

    &__offerWrapper {
      display: grid;
      grid-template-columns: 1fr 500px; }

    &__offer-left-block {
      padding: 32px 48px;
      display: grid;
      grid-template-columns: 380px auto;
      gap: 20px;
      align-content: flex-start; }

    &__offer-right-block {
      padding: 0 48px;

      .checkbox {
        margin-top: 20px;
        width: max-content; }

      .partizan-open-selling__step-info {
        margin: 0 -48px;
        min-width: 350px; } }

    &__selected-tickets {
        // height: 450px
        overflow: auto;
        @include respond-to('small-max') {
            height: 300px; }
        &-item {
            display: flex;
            margin-top: 12px;
            border-radius: 8px;
            background: #FFF;
            padding: 16px;
            gap: 1rem;
            flex-wrap: wrap;
            @include respond-to('medium-max') {
                align-items: flex-start;
                flex-direction: column; }
            @include respond-to('small-max') {
                flex-direction: row;
                align-items: center; }
            &-labels {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
                .price {
                    text-wrap: nowrap; } }

            .checkbox {
                width: 100%; } } }
    &__total {
        margin-top: 40px;
        > div {
            display: flex;
            justify-content: space-between; } }
    &__text {
        &-bold {
            margin: 0;
            font-size: 16px;
            font-weight: 700;
            &-small {
                margin: 0;
                font-size: 14px;
                font-weight: 700; }
            &-large {
                margin: 0;
                font-size: 18px;
                font-weight: 700; } }
        &-medium {
            &-small {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                color: #0C0019; } }
        &-normal {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            &-small {
                margin: 0;
                font-size: 14px;
                font-weight: 400; } }
        &-error {
            color: var(--reject-button-color); } }
    &__payment-method {
        width: 100%;
        max-width: 400px; }

    &__fields-group {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        button {
            @include respond-to('small-max') {
                flex: 44px; } } }
    &__fields-label {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    &__validation-fields-group {
        width: 400px; }
    &__terms-of-service-text {
        display: inline-block;
        margin-left: 3px;
        color: var(--secondary-color);
        font-weight: 600; }
    &__empty-tickets {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            color: #9FA0A6;
            font-size: 14px;
            font-weight: 400; } }
    &__select-section {
        overflow: auto;
        height: 500px;
        @include respond-to('small-max') {
            height: 300px; }
        &_no-scroll {
            overflow: visible; }
        &-ticket {
            background-color: $color-seashell;
            border-radius: 8px;
            padding: 16px;
            &:not(:last-child) {
                margin-bottom: 15px; }
            p {
                margin: 0; }
            > div {
                display: flex;
                justify-content: space-between; }
            > div:not(:last-child) {
                margin-bottom: 12px; }
            > div:last-child {
                justify-content: end; }
            &-unavailable {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $color-light;
                p {
                    color: #9FA0A6;
                    font-size: 16px;
                    font-weight: 400; } }
            &-count {
                display: flex;
                align-items: center;
                padding: 2px 12px;
                border-radius: 5px;
                background: $color-light;
                button {
                    border: none;
                    background-color: transparent;
                    path {
                        stroke: var(--primary-color); } } }
            &-capacity {
                font-size: 14px;
                font-weight: 600;
                margin: 0 15px; } } } }

.select-ticket-popup-mode {
    // background-color: red
    margin: 0;
    padding: 1rem; }
