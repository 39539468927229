@import "../../style/index";

.scan-teams {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh);

  .page-title {
    margin: 36px 0;

    @media all and (max-width: 768px) {
      width: 100%;
      padding: 10px;
      margin: 0;

      position: fixed;
      top: 0;
      z-index: 2;
      box-sizing: border-box;

      text-align: center;
      font-size: 18px;
      font-weight: bold;
      display: block;
      background-color: $color-light; } }

  .page-footer {
    margin-top: auto;

    button {
      padding: 10px 30px; } }

  .ReactTable {
    background: white;
    max-height: calc(100vh - 210px);

    .rt-thead {
      flex-grow: 0;
      padding: 0;
      &.-header {
          .rt-tr {
              .rt-th {
                  display: flex;
                  align-items: center;
                  padding: 20px;
                  transition: linear .07s;

                  padding: 20px;
                  box-sizing: content-box; } } } }

    .rt-tbody {
      padding: 0;
      box-sizing: content-box;
      flex: 99999 1 auto;

      .rt-tr-group {
        transition: all 0.2s linear;
        display: block;

        &:hover {
          background-color: var(--secondary-light-color); }

        .rt-td {
          cursor: pointer;
          border-right: none;
          margin-right: 0;
          padding: 20px;
          box-sizing: content-box;
          text-wrap: wrap;
          display: flex;
          align-items: center;

          .scan-teams-list-item {
            align-self: flex-start; }

          .btn-primary {
            display: flex;
            align-items: center;

            &:disabled {
              svg {
                g {
                  stroke: $color-silver-chalice; } } } } } } }

    .rt-tr {
      display: flex;
      max-height: 80px;

      &.active {
        max-height: none;
        button {
          transform: rotate(180deg); } } } }

  &:last-of-type {
    margin-bottom: 0; }

  .strong {
    color: #000000;
    font-family: var(--app-font-family);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.54px;
    line-height: 20px; }
  p {
    color: #000000;
    font-family: var(--app-font-family);
    font-size: 15px;
    letter-spacing: 0.54px;
    line-height: 20px;
    margin: 10px 0 20px;
    word-wrap: break-word;
    display: flex; }
  ul {
    margin: 0;
    margin-bottom: 40px;
    padding: 0;
    list-style: none;

    li {
      color: #000000;
      font-family: var(--app-font-family);
      font-size: 15px;
      letter-spacing: 0.54px;
      line-height: 25px; } }

  @media all and (max-width: 768px) {
    .page-footer {
      justify-content: center; }

    .ReactTable {
      display: none; } }

  & .loader {
    height: 50vh; } }
